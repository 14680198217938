<template>
  <div class="container mt-4 mb-3">
    <div class="row" style="justify-content: space-around; align-items: center">
      <div>
        <h1 style="font-size: 30px; color: #a1000c; font-weight: bold">
          Veja nossa biblioteca de aplicativos
        </h1>
        <p>Você pode adicionar novos aplicativos e sites ao sistema</p>
      </div>
      <div>
        <img src="/assets/img/library.jpg" />
      </div>
    </div>

    <div class="row mt-4" style="justify-content: center">
      <div class="col-8">
        <b-form-input
          id="input-horizontal"
          placeholder="Pesquise aplicativos..."
          style="border: 1px solid #a1000c"
          class="input px-3"
        ></b-form-input>
      </div>
      <div>
        <b-button variant="danger mr-2" type="submit">Filtrar</b-button>

        <b-button variant="success" type="submit" v-b-modal.modal-lg
          >Novo Aplicativo</b-button
        >

        <!-- Início do modal -->
        <b-modal
          id="modal-lg"
          size="lg"
          title="Adicionar Aplicativo"
          style="color: rgb(161, 0, 12) !important"
        >
          <form enctype="multipart/form-data" action="#" method="post">
            <div class="row">
              <div class="col-md-4 mb-3">
                <div role="group">
                  <label for="input-name">Nome</label>
                  <b-form-input
                    id="input-name"
                    v-model="app.name"
                    :state="nameState"
                    aria-describedby="input-live-help input-live-feedback"
                    placeholder="ERP"
                    trim
                  ></b-form-input>

                  <!-- Esse campo so será exibido caso a validação do input "Nome" esteja inválida -->
                  <b-form-invalid-feedback id="input-live-feedback">
                    Digite pelo menos 3 letras
                  </b-form-invalid-feedback>
                </div>
              </div>

              <!-- input de URL -->
              <div class="col-md-8 mb-3">
                <label for="input-url">URL</label>
                <b-form-input
                  type="url"
                  id="input-url"
                  v-model="app.url"
                  placeholder="https://example.com.br"
                ></b-form-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4 mb-3">
                <label for="inputState">Selecione a logo</label>
                <input
                  class="form-control"
                  @change="onFileChange"
                  type="file"
                  id="formFile"
                />
              </div>
              <!-- input de Área -->
              <div class="col-md-4 mb-3">
                <label for="inputState">Área</label>

                <select
                  id="inputState"
                  v-model="app.areas"
                  class="form-control"
                >
                  <option selected>Escolha...</option>
                  <option v-for="(area, i) in areas" :key="i" :value="area.id">
                    {{ area.name }}
                  </option>
                </select>
              </div>

              <!-- input de Setor -->
              <div class="col-md-4 mb-3">
                <label for="inputState">Setor</label>
                <select
                  multiple
                  id="inputState"
                  v-model="app.departments"
                  class="form-control"
                >
                  <option selected>Escolha...</option>
                  <option
                    v-for="(department, i) in departments"
                    :key="i"
                    :value="department.id"
                  >
                    {{ department.name }}
                  </option>
                </select>
              </div>

              <div class="col-md-4 mb-3">
                <b-form-group
                  label="O aplicativo é:"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    class="mt-2"
                    v-model="appPublic"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="radio-inline"
                  ></b-form-radio-group>
                </b-form-group>
              </div>
            </div>
          </form>
          <template #modal-footer="{ ok, cancel }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button size="mg" variant="secondary" @click="cancel()">
              Cancelar
            </b-button>
            <b-button size="mg" variant="danger" @click="createApp">
              Salvar
            </b-button>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass">
@import "~/public/assets/styles/scss/pages/library.scss"
</style>

<script>
import AreaService from "../../../services/AreaService/AreaService";
import DepartmentService from "../../../services/DepartmentService/DepartmentService";
import AppService from "../../../services/AppService/AppService";

export default {
  name: "HomeHead",
  props: ["app"],

  data() {
    return {
      name: "",
      areas: [],
      areaService: null,
      departments: [],
      departmentService: null,
      appPublic: "first",
      options: [
        { text: "Público", value: "true" },
        { text: "Privado", value: "false" },
      ],
    };
  },
  //Script para validar nomes acima de 3 caracteres
  computed: {
    nameState() {
      return this.app.name.length > 2 ? true : false;
    },
  },

  mounted() {
    this.appService = new AppService();
    this.areaService = new AreaService();
    this.getAllAreas();
    this.departmentService = new DepartmentService();
    this.getAllDepartments();
  },

  methods: {
    async getAllAreas() {
      this.areas = await this.areaService.allAreas();
    },

    async getAllDepartments() {
      this.departments = await this.departmentService.allDepartments();
    },

    async createApp() {
      this.app.public = this.appPublic;
      await this.appService.createApp(this.app);
    },

    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.app.logo = files[0];
    },
  },
};
</script>
