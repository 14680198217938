<template>
  <div class="container">
    <div class="row mb-5 justify-content-center">
      <div class="aplicativo mt-4" v-for="(app, i) in apps" :key="i">
        <div
          class="d-flex justify-content-center"
          style="cursor: pointer"
          @click="getApp(app.id)"
        >
          <div class="">
            <div class="d-flex justify-content-center">
              <img v-bind:src="app.logo_thumb" width="64px" height="64px" />
            </div>
            <div
              class="mt-2"
              style="text-align: center; font-size: 14px; color: black"
            >
              {{ app.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass">
@import "~/public/assets/styles/scss/pages/library.scss"
</style>

<script>
export default {
  name: "LibraryBody",
  props: ["apps"],

  methods: {
    getApp: async function (id) {
      let appId = id;
      if (appId) {
        return this.$router.push({ path: `/library/app/${appId}` });
      }
    },
  },
};
</script>
