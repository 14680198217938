<template>
  <div class="mt-5">
    <LibraryHead :app="app" />
    <LibraryBody :apps="apps" />
  </div>
</template>

<style scoped lang="sass"></style>

<script>
import LibraryHead from "./components/LibraryHead.vue";
import LibraryBody from "./components/LibraryBody.vue";

import AppService from "../../services/AppService/AppService";

export default {
  components: { LibraryHead, LibraryBody },
  name: "LibraryPage",
  data() {
    return {
      apps: [],
      appService: null,
      app: {
        desc: "Padrão",
        is_active: true,
        is_web: false,
        logo: null,
        logo_thumb: null,
        name: "",
        supports_jwt: false,
        url: "",
      },
    };
  },
  mounted() {
    this.appService = new AppService();
    this.getAllApps();
  },
  methods: {
    async getAllApps() {
      this.apps = await this.appService.allApps();
    },
  },
};
</script>
